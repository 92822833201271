import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import HeroWithText from '../components/HeroWithText';

import { h1Style, h2Style, introStyle } from '../styles/global';
import { greaterThan } from '../utils/mediaQuery';
import breakpoints from '../styles/breakpoints';

export const selfBreakpoints = {
  sm: breakpoints[2],
  md: breakpoints[4],
  lg: breakpoints[6],
};

export const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

export const TextWrapper = styled.div`
  padding: 0 ${rem(28)};
  margin-bottom: ${rem(100)};

  /* line-height: 1.7 ; */

  > h2 {
    margin-bottom: ${rem(20)};
  }

  > * + h2 {
    margin-top: ${rem(80)};
  }

  ul > li + li {
    margin-top: ${rem(10)};
  }

  > :is(ul, p) + :is(ul, p) {
    margin-top: ${rem(40)};
  }

  ${greaterThan(selfBreakpoints.sm)} {
    padding: 0 ${rem(80)};
  }

  ${greaterThan(selfBreakpoints.md)} {
    margin: ${rem(40)} ${rem(40)} ${rem(120)};
    padding: 0 ${rem(40)};
  }

  ${greaterThan(selfBreakpoints.lg)} {
    padding: 0 ${rem(80)};
  }
`;

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <SEO
        title='Politiques de confidentialité'
        description="Date d'entrée en vigueur : 15 septembre 2023"
      />
      <article>
        <HeroWithText noPicture>
          <h1 css={h1Style}>Politique de confidentialité</h1>

          <p>
            <strong>Date d&#39;entrée en vigueur: 15 septembre 2023</strong>
          </p>

          <p css={introStyle}>
            Maxillo Québec SENC (ci-après « MQ » ou « nous ») se soucie du
            respect de votre vie privée et de la protection de vos
            renseignements personnels. Maxillo Québec s&apos;engage à protéger
            vos renseignements personnels et à ne les utiliser qu&apos;aux fins
            auxquelles ils ont été recueillis. Cette politique de
            confidentialité explique comment nous recueillons, partageons,
            utilisons et protégeons vos renseignements personnels conformément
            aux lois et réglementations applicables.
          </p>
        </HeroWithText>

        <Container>
          <TextWrapper>
            <h2 css={h2Style}>1. Consentement via le site web</h2>
            <p>
              En accédant au site web, et en nous fournissant des renseignements
              personnels, vous acceptez la présente politique et consentez à ce
              que des renseignements personnels à votre sujet soient recueillis,
              utilisés, communiqués, conservés et détruits conformément aux
              modalités décrites dans la présente politique. Si vous
              n&apos;acceptez pas cette politique relative à la vie privée,
              veuillez cesser de naviguer sur le site web ou de l&apos;utiliser,
              ou d&apos;utiliser selon le cas les autres services de Maxillo
              Québec.
              <br />
              MQ suit des normes rigoureuses en matière de sécurité, de respect
              de la vie privée et de protection des renseignements personnels et
              considère comme sa plus haute priorité la nécessité d&apos;assurer
              la sécurité et la confidentialité des renseignements personnels
              que vous lui fournissez.
            </p>

            <h2 css={h2Style}>2. Portée et application</h2>
            <p>
              Le terme « renseignement personnel » désigne les renseignements
              qui, lorsque pris seul ou en combinaison avec d&apos;autres
              renseignements, permettent d&apos;identifier un individu, par
              exemple son nom, sa date de naissance, son adresse courriel, et
              tout autre renseignement devant être traité comme un «
              renseignement personnel » conformément aux lois applicables.
            </p>

            <h2 css={h2Style}>3. Collecte des renseignements personnels</h2>
            <p>
              Dans le cadre de la fourniture de nos services, nous devons
              recueillir certains renseignements concernant les personnes
              physiques et qui permettent de les identifier. Les types de
              renseignements personnels qui sont recueillis MQ dépendent de
              plusieurs facteurs et du contexte des soins demandés. Nous ne
              recueillons que les renseignements personnels nécessaires pour la
              conduite des activités et pour la réalisation des fins
              déterminées.
            </p>

            <h2 css={h2Style}>4. Informations recueillies</h2>
            <p>
              Nous recueillons des renseignements personnels auprès de nos
              patients pour leur fournir des services en médecine buccale. Ces
              informations peuvent inclure, sans se limiter:
            </p>
            <ul>
              <li>
                des coordonnées telles que votre nom, votre adresse postale,
                votre adresse courriel et votre numéro de téléphone;
              </li>
              <li>
                des informations démographiques telles que votre âge et votre
                genre;
              </li>
              <li>
                des informations sur la santé telles que vos antécédents
                médicaux, vos médicaments actuels et vos allergies;
              </li>
              <li>
                des informations sur votre assurance dentaire et/ou votre numéro
                de RAMQ;
              </li>
              <li>
                des informations biométriques telles que radiographies et
                imageries dentaires.
              </li>
            </ul>

            <h2 css={h2Style}>
              5. Mode de collecte des renseignements personnels
            </h2>
            <p>
              Nous collectons vos informations directement auprès de vous
              lorsque vous désirez obtenir un produit ou un service offert par
              MQ ou que vous communiquez avec nous, quel que soit le moyen
              utilisé (en personne, par téléphone, courriel ou clavardage en
              ligne). Lorsque vous naviguez sur notre site web ou lorsque vous
              utilisez nos services en ligne ou même en personne, nous
              recueillons des renseignements à votre sujet.
            </p>
            <p>
              Il en est de même si vous communiquez avec nous pour obtenir de
              l&apos;information sur votre dossier. Nous devons vous identifier,
              par exemple, en vous demandant de répondre à des questions
              d&apos;authentification. Il peut s&apos;agir de renseignements
              requis pour ouvrir un dossier et devenir client, tels que vos nom,
              prénom et adresse, jusqu&apos;à de l&apos;information plus
              spécifique comme un bilan de santé ou un historique
              d&apos;accidents pour une soumission auprès de vos assurances
              dentaires ou médicales d&apos;urgence.
            </p>
            <p>
              Selon la situation, il se peut que nous devions recueillir des
              renseignements à votre sujet auprès d&apos;autres personnes,
              organismes ou entités, par exemple:
            </p>

            <ul>
              <li>paiements bancaires;</li>
              <li>
                personne avec laquelle vous codétenez un produit ou service, que
                vous cautionnez ou qui vous cautionne
              </li>
              <li>fournisseurs de services;</li>
              <li>organismes publics;</li>
              <li>références personnelles;</li>
              <li>
                sources publiques telles que registres, documents officiels,
                sites Internet;
              </li>
              <li>autres sources.</li>
            </ul>

            <h2 css={h2Style}>6. Raisons de la prise des renseignements</h2>
            <p>
              Les renseignements personnels qui sont recueillis et utilisés le
              sont notamment aux fins suivantes :
            </p>

            <ul>
              <li>vous identifier;</li>
              <li>
                savoir à qui nous fournissons des produits et services en
                vérifiant que vous êtes bel et bien la personne que vous dites
                être (pour vous identifier et valider votre identité);
              </li>
              <li>
                nous assurer que vos renseignements personnels sont exacts,
                complets et à jour avant de les utiliser. Il est toutefois de
                votre responsabilité de nous informer de tout changement vous
                concernant;
              </li>

              <li>
                pour vous faire des recommandations et services personnalisés et
                vous inviter à des consultations si vous nous avez donné votre
                accord à cet effet;
              </li>
              <li>évaluer vos états de comptes;</li>
              <li>réévaluer vos engagements financiers envers nous;</li>
              <li>
                évaluer si le produit ou le service demandé répond à votre
                besoin et à vos objectifs, si requis;
              </li>
              <li>consulter et mettre à jour votre dossier;</li>
              <li>vous fournir le produit ou le service demandé;</li>
              <li>
                vous accompagner, vous conseiller et vous aider à prendre les
                meilleures décisions en fonction du besoin exprimé, de votre
                situation et de vos objectifs;
              </li>
              <li>
                concevoir des plans de traitement pour vous offrir des produits,
                des services et des conseils qui répondent à vos besoins;
              </li>
              <li>
                assurer l&apos;administration des produits et services que vous
                détenez;
              </li>
              <li>traiter vos réclamations d&apos;assurances;</li>
              <li>communiquer avec vous;</li>
              <li>
                obtenir votre opinion à la suite d&apos;une interaction avec MQ
                ou en lien avec un produit ou un service;
              </li>
              <li>
                vous fournir de l&apos;accompagnement et du soutien, répondre à
                vos demandes d&apos;information et vous orienter vers la bonne
                personne selon votre demande;
              </li>
              <li>traiter les plaintes et les insatisfactions.</li>
            </ul>

            <h2 css={h2Style}>
              7. Conservation et communication des renseignements
            </h2>
            <p>
              Maxillo Québec conserve les renseignements personnels pendant une
              période raisonnable, soit pour répondre entièrement à vos
              demandes, soit pour atteindre les fins énoncées de la loi selon le
              délai qui est le plus long. Seuls les membres du personnel
              concernés peuvent accéder aux données lorsque cela est nécessaire
              à l&apos;accomplissement de leurs fonctions liées aux services et
              conformément aux fins énoncées dans la présente politique de vie
              privée, sous réserve du paragraphe suivant. Les données
              personnelles sont traitées et conservées aussi longtemps que
              requis pour la finalité pour laquelle elles ont été collectées.
              Par conséquent:
            </p>
            <ul>
              <li>
                les données personnelles collectées à des fins liées à
                l&apos;exécution d&apos;un contrat entre le propriétaire et
                l&apos;utilisateur doivent être conservées jusqu&apos;à la
                pleine exécution du contrat
              </li>
              <li>
                selon l&apos;Ordre des dentistes du Québec : « Les informations
                consignées dans le dossier, qu&apos;elles soient archivées ou
                non, doivent être conservées pendant une période minimale de
                cinq années suivant la dernière inscription au dossier, ou la
                dernière pièce jointe à ce dossier ».
              </li>
            </ul>

            <p>
              Nous ne divulguons jamais vos renseignements personnels à
              quiconque à l&apos;extérieur de Maxillo Québec sans votre
              consentement, à l&apos;exception des tiers suivants et seulement
              si ceux-ci ont mis en place les garanties de sécurité appropriées
              afin d&apos;assurer au moins le même niveau de sécurité que le
              nôtre.
            </p>

            <p>
              Nos fournisseurs et partenaires dont les services ont été retenus
              afin de s&apos;acquitter de certaines fonctions pour notre compte
              (ex. traitement et conservation de données, l&apos;envoi de
              communications, l&apos;analyse de la qualité du service, la
              gestion du système de prise de rendez-vous ainsi que:
            </p>

            <ul>
              <li>
                les spécialistes dentaires et médicaux (médecins, pharmaciens
                (etc…)
              </li>
              <li>les entreprises de services de laboratoire dentaire</li>
              <li>
                les entreprises de services et de produits informatiques
                (logiciel dentaire, terminal de paiement, transmission aux
                assurances)
              </li>
              <li>
                les services infonuagiques, d&apos;hébergement Web et de
                traitement de données
              </li>
              <li>les services juridiques</li>
              <li>
                les agences d&apos;évaluation du crédit ou de recouvrement
              </li>
              <li>
                les entreprises de gestion des ressources humaines et de
                formation.
              </li>
            </ul>
            <p>
              Dans ce cas, nous prenons les mesures nécessaires afin de
              s&apos;assurer que ces tiers n&apos;ont accès qu&apos;aux
              renseignements qui sont nécessaires pour mener à bien les services
              convenus, qu&apos;ils traitent vos renseignements aux seules fins
              que nous avons déterminées et qu&apos;ils ont en place des mesures
              de protection des renseignements personnels conformes aux lois
              applicables et permettant de respecter les mêmes conditions et le
              même niveau de protection que ce qui est édicté dans la présente
              politique de vie privée:
            </p>
            <ul>
              <li>
                Toute entité, advenant une fusion, une réorganisation
                corporative ou un changement de contrôle de Maxillo Québec, en
                tout ou en partie (uniquement si les parties ont signé une
                entente stipulant que la collecte, l&apos;utilisation et la
                divulgation de l&apos;information) est limitée aux objectifs de
                la transaction commerciale.
              </li>
              <li>
                Toute autorité publique, si la loi l&apos;exige, ou pour
                défendre nos droits, ou autrement conformément à la loi. Si MQ
                doit divulguer des renseignements ou fournir une copie de ces
                renseignements à une agence gouvernementale en vertu d&apos;une
                loi ou conformément à une ordonnance d&apos;un tribunal, MQ
                essaiera de vous en aviser au préalable dans la mesure où la loi
                le permet.
              </li>
            </ul>

            <h2 css={h2Style}>8. Droit d&apos;accès</h2>
            <p>
              Nous nous efforçons de rendre Maxillo Québec et notre site Web
              universellement accessible et nous travaillons en permanence pour
              améliorer l&apos;accessibilité de son contenu.
            </p>
            <p>
              Nous nous engageons à reconnaître un droit d&apos;accès et de
              rectification aux personnes concernées désireuses de consulter,
              modifier, voire radier les informations les concernant.
            </p>

            <h2 css={h2Style}>9. Droit d&apos;opposition et de retrait</h2>
            <p>
              Vous pouvez, en tout temps, retirer votre consentement à
              l&apos;utilisation des renseignements personnels que vous nous
              avez fournis directement dans le cadre de la performance des
              services ou en demander la destruction.
            </p>

            <p>
              Advenant la confirmation du retrait de votre consentement ou de
              votre demande de destruction des renseignements personnels, nous
              procéderons, dans un délai raisonnable, soit à la destruction,
              l&apos;effacement ou l&apos;anonymisation de ces renseignements
              personnels dans le cas d&apos;un retrait de consentement ou soit
              dans le cas d&apos;une demande de destruction des renseignements
              personnels.
            </p>

            <p>
              Pour toute demande relative au retrait de votre consentement à
              l&apos;utilisation de ces renseignements personnels ou la
              destruction de ceux-ci, veuillez communiquer directement avec
              nous.
            </p>

            <h2 css={h2Style}>10. Sécurité</h2>
            <p>
              Nous prenons des mesures raisonnables afin de protéger vos
              renseignements personnels contre tout accès, utilisation ou
              divulgation non autorisés. Malgré cela, aucun mécanisme de
              sécurité ne peut garantir une protection parfaite et il existera
              toujours un certain risque résiduel.
            </p>
            <p>
              Nous utilisons des systèmes de dossiers sécurisés, une gestion
              efficace des identités et des accès et des protections physiques
              pour protéger vos informations, le tout conformément aux lois et
              réglementations en vigueur.
            </p>
            <p>
              Maxillo Québec décline toute responsabilité au fait que de telles
              informations vous concernant pourraient être divulguées, sauf en
              cas de négligence de la part de MQ. Si vous avez des raisons de
              croire que des renseignements personnels ont été compromis,
              veuillez communiquer avec nous.
            </p>
            <p>
              Toutes les données recueillies dans le cadre de votre utilisation
              du site web sont généralement conservées sur des serveurs situés
              au Canada. Toutefois, les données peuvent être traitées par des
              tiers fournisseurs de services de Maxillo Québec situés à
              l&apos;extérieur du Canada afin de leur permettre de
              s&apos;acquitter de mandats au nom de MQ. Dans ce dernier cas, MQ
              prend les moyens raisonnables pour s&apos;assurer que ces tiers
              sont liés par les garanties appropriées.
            </p>

            <h2 css={h2Style}>
              11. Changements à la politique de confidentialité
            </h2>
            <p>
              Maxillo Québec se réserve le droit de changer la présente
              politique de vie privée à tout moment sans préavis. La version à
              jour de la politique de vie privée est disponible sur le site web
              et sa date d&apos;entrée en vigueur y est indiquée. Si des
              changements de nature à modifier de manière importante le risque
              en matière de vie privée sont apportés à la politique, nous vous
              en aviserons au moyen d&apos;un avis publié sur la page
              d&apos;accueil du site web ou nous communiquerons avec vous pour
              vous en informer, lorsque requis par la loi. Si vous continuez
              d&apos;utiliser le site web après l&apos;entrée en vigueur de tels
              changements, vous serez réputé avoir accepté ces changements. Nous
              vous recommandons de consulter régulièrement notre politique de
              vie privée.
            </p>

            <p>
              <i>
                La présente politique a été mise à jour pour la dernière fois en
                septembre 2023.
              </i>
            </p>
          </TextWrapper>
        </Container>
      </article>
    </Layout>
  );
};

export default PrivacyPolicyPage;
