// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import {
  lessThanCondition,
  greaterThanCondition,
} from '../../utils/mediaQuery';

import {
  StyledHeader,
  TextWrapper,
  StyledPicture,
  selfBreakpoints,
  PictureWrapper,
  IconWrapper,
  PictureContainer,
} from './HeroWithText.styles';

const HeroWithText = ({
  children,
  pictureSmall,
  pictureLarge,
  stamp,
  noPicture,
}) => {
  const sources = [
    { ...pictureSmall, sizes: `${(740 / selfBreakpoints.sm) * 100}vw` },
    {
      ...pictureLarge,
      media: `${greaterThanCondition(selfBreakpoints.sm)}`,
      sizes: `${lessThanCondition(selfBreakpoints.md)} ${
        (912 / selfBreakpoints.md) * 100
      }vw, (max-width: 1560px) 50vw`,
    },
  ];

  return (
    <StyledHeader>
      {!noPicture && (
        <PictureContainer>
          <PictureWrapper>
            <StyledPicture fluid={sources} loading='eager' fadeIn={false} />
          </PictureWrapper>

          {stamp && <IconWrapper>{stamp}</IconWrapper>}
        </PictureContainer>
      )}

      <TextWrapper fullWidth={noPicture}>{children}</TextWrapper>
    </StyledHeader>
  );
};

HeroWithText.propTypes = {
  children: PropTypes.node.isRequired,
  pictureLarge: PropTypes.shape({
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string.isRequired,
    srcSetWebp: PropTypes.string.isRequired,
  }).isRequired,
  pictureSmall: PropTypes.shape({
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string.isRequired,
    srcSetWebp: PropTypes.string.isRequired,
  }).isRequired,
  stamp: PropTypes.node,
  noPicture: PropTypes.bool,
};

HeroWithText.defaultProps = {
  stamp: undefined,
  noPicture: false,
};

export default HeroWithText;

export const query = graphql`
  fragment HeroWithTextLargePictureData on File {
    childImageSharp {
      fluid(maxWidth: 950, maxHeight: 606, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }

  fragment HeroWithTextSmallPictureData on File {
    childImageSharp {
      fluid(maxWidth: 950, maxHeight: 774, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`;
