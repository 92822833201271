// vendors
import styled, { css } from 'styled-components';
import { rem, between } from 'polished';
import Img from 'gatsby-image';
import colors from '../../styles/colors';
import { greaterThan } from '../../utils/mediaQuery';
import breakpoints from '../../styles/breakpoints';
import responsiveStyle from '../../utils/responsiveStyle';

export const selfBreakpoints = {
  sm: breakpoints[2],
  md: breakpoints[4],
  lg: breakpoints[6],
};

export const StyledHeader = styled.header`
  max-width: 1440px;
  margin: auto;

  ${responsiveStyle('marginTop', [0, 0, undefined, 88], breakpoints.spacings)};
  ${responsiveStyle('marginBottom', [64, 168], breakpoints.spacings)};

  ${greaterThan(selfBreakpoints.md)} {
    display: flex;

    align-items: center;
  }
`;

export const TextWrapper = styled.div`
  padding: 0 ${rem(28)};

  ${greaterThan(selfBreakpoints.sm)} {
    padding: 0 ${rem(80)};
  }

  ${greaterThan(selfBreakpoints.md)} {
    flex: 1 1 50%;
    max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '640px')};
    margin: ${rem(40)};
    padding: 0 ${rem(40)};
  }

  ${greaterThan(selfBreakpoints.lg)} {
    padding: 0 ${rem(80)};

    > h1 {
      margin-top: 0;
    }
  }
`;

const squareStyles = css`
  position: relative;

  ::after {
    position: absolute;
    top: 100%;
    right: 0;

    display: block;
    width: ${(252 / 292) * 100}%;
    height: ${rem(120)};

    background-color: ${colors.robinEggBlue};
    transform: translateY(-50%);

    mix-blend-mode: multiply;

    content: '';

    ${greaterThan(selfBreakpoints.sm)} {
      width: ${(565 / 768) * 100}%;
      height: 0;
      padding-bottom: ${(176 / 768) * 100}%;

      transform: translateY(-33%);
    }

    ${greaterThan(selfBreakpoints.md)} {
      width: ${(610 / 780) * 100}%;
      padding-bottom: ${(200 / 780) * 100}%;

      transform: translateY(-33%);
    }

    ${greaterThan(1440)} {
      right: ${between('0px', '60px', '1440px', '1560px')};
    }

    ${greaterThan(1560)} {
      right: ${rem(60)};
    }
  }
`;

export const PictureContainer = styled.div`
  order: 1;

  margin-left: ${rem(28)};

  ${greaterThan(selfBreakpoints.sm)} {
    margin-left: ${rem(80)};
  }

  ${greaterThan(selfBreakpoints.md)} {
    flex: 1 1 50%;
    max-width: 780px;
    margin-left: 0;
  }

  ${greaterThan(1440)} {
    margin-right: ${between('0px', '-60px', '1440px', '1560px')};
  }

  ${greaterThan(1560)} {
    margin-right: ${rem(-60)};
  }
`;

export const PictureWrapper = styled.div`
  ${squareStyles};
`;

export const StyledPicture = styled(Img)`
  ${greaterThan(selfBreakpoints.md)} {
    max-width: 780px;
  }
`;

export const IconWrapper = styled.div`
  width: 123px;
  height: 123px;

  margin: 12px auto 0;

  ${greaterThan(selfBreakpoints.sm)} {
    width: 168px;
    height: 168px;

    margin: 28px 80px 0 auto;
  }

  ${greaterThan(selfBreakpoints.md)} {
    width: 192px;
    height: 192px;

    margin: 80px auto 0;
  }

  ${greaterThan(1440)} {
    transform: translateX(${between('0px', '-60px', '1440px', '1560px')});
  }

  ${greaterThan(1560)} {
    transform: translateX(${rem(-60)});
  }
`;
